<template>
  <div class="infotablist">
   <div class="tab-container">
       <ul>
           <li 
           v-for="item in list" 
           :key="item.id"
           :class="{addbg:current === item.id}"
           @click="handler(item.title,item.id)"
           >{{item.title}}</li>
       </ul>
   </div>
  </div>
</template>

<script>
export default {
  name: 'Infotablist',
  props:{
    currentIndex:{
      type:Number,
      default:1
    }
  },
  data () {
      return {
          list : [
              {
                id: 1,
                title: "通知公告"
              },
              {
                id: 2,
                title: "捐款公示"  
              },
              {
                id: 3,
                title: "捐物公示"  
              },
              {
                id: 4,
                title: "捐赠使用公示"  
              },
              {
                id: 5,
                title: "人道救助公示"  
              },
              {
                id: 6,
                title: "项目公示"  
              },
              {
                id: 7,
                title: "年度审计报告公示"  
              },
			  {
			    id: 8,
			    title: "专项审计报告公示"  
			  },
              {
                id: 9,
                title: "预决算公示"
              },
              {
                id: 10,
                title: "规划与年鉴"  
              },
              {
                id:11,
                title:"其他公示"
              }
          ],
          current:1
      }
    },
    methods: {
        handler (title,id) {
            this.$emit('listenTochild',title)
            this.current = id
        }
    },
    mounted() {
    this.current = this.$route.query.currentIndex?parseInt(this.$route.query.currentIndex):1;
      this.list.forEach(item =>{
        if(item.id == this.current){
          this.handler(item.title,item.id)
        }
      })
    },

}
</script>

<style lang="less" scoped>
.tab-container {
    width: 180px;
    min-height: 576px;
    background-color: #ffffff;  
    box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
    margin-right: 32px;
    cursor: pointer;
    ul {
        width: 100%;
        height: 100%;
        li {
           height: 11.11%;
           text-align: center;
           line-height: 64px; 
           color: #666666;
           font-weight: bolder;
          //  &:nth-child(1) {
          //      background-color: #D1292E;
          //      color: #ffffff;
          //  }
        }
        .addbg {
           background-color: #D1292E;
           color: #ffffff;
        }
    }
}
</style>
